<template>
  <!-- 虚拟号模板 -->
  <div class="virtual-numbe-configuration-page">
    <div class="add-btn">
      <el-button
        v-if="listFind('添加')"
        type="primary"
        size="small"
        @click="openDialogFun('新增')"
        >添加</el-button
      >
    </div>
    <div class="table-box">
      <Table
        :table-data="tableData"
        :title-name="titleName"
        operation
        col-class-type
        operation-width="200"
      >
        <template slot-scope="scope">
          <div>
            <template>
              <el-button
                v-if="listFind('查看')"
                type="text"
                size="small"
                sort="primary"
                @click="openDialogFun('查看', scope.scopeRow)"
                >查看</el-button
              >
              <el-button
                v-if="listFind('编辑')"
                type="text"
                size="small"
                sort="primary"
                @click="openDialogFun('编辑', scope.scopeRow)"
                >编辑</el-button
              >
              <!-- <el-button
                v-if="listFind('删除')"
                type="text"
                size="small"
                sort="primary"
                @click="onDelete(scope.scopeRow)"
              >删除</el-button>-->
            </template>
          </div>
        </template>
      </Table>
    </div>
    <div ref="pagination" class="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="form.pageSize"
        :current-page="form.currentPage"
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      ></el-pagination>
    </div>
    <div class="dialog-box">
      <el-dialog
        v-if="dialogVisible"
        :title="dialogTitle"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
        width="650px"
      >
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="140px"
          class="demo-ruleForm"
        >
          <el-form-item label="渠道：" prop="channelNo">
            <el-select
              v-model="channelNo"
              :disabled="dialogTitle === '编辑' || dialogTitle === '查看'"
              value-key="channelNo"
              clearable
              placeholder="请选择渠道"
              @change="changeSelect"
            >
              <el-option
                v-for="(item, index) in channelList"
                :key="index"
                :label="item.channelName"
                :value="item"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="供应商：" prop="productType">
            <el-select
              v-model="ruleForm.productType"
              :disabled="dialogTitle != '新增'"
              clearable
              placeholder="请选择供应商"
            >
              <el-option label="华为云" :value="1"></el-option>
              <el-option label="阿里云" :value="0"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="accessKeyId：" prop="accessKeyId">
            <el-input
              v-model.trim="ruleForm.accessKeyId"
              placeholder="请输入accessKeyId"
              :disabled="dialogTitle === '查看'"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="accessKeySecret：" prop="accessKeySecret">
            <el-input
              v-model.trim="ruleForm.accessKeySecret"
              placeholder="请输入accessKeySecret"
              :disabled="dialogTitle === '查看'"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="号码池Key：" prop="poolKey">
            <el-input
              v-model.trim="ruleForm.poolKey"
              placeholder="请输入号码池Key"
              :disabled="dialogTitle === '查看'"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="虚拟号码池：" prop="phones">
            <div class="addNumberBox">
              <div
                v-for="(item, index) in ruleForm.phones"
                :key="index"
                style="position: relative"
              >
                <el-input
                  v-model.trim="ruleForm.phones[index]"
                  clearable
                  maxlength="11"
                  :disabled="dialogTitle === '查看'"
                  placeholder="请输入虚拟号码池"
                  onkeyup="this.value=this.value.replace(/[^0-9]/g,'')"
                ></el-input>
                <el-button
                  v-show="index >= 1"
                  class="reduce"
                  :disabled="dialogTitle === '查看'"
                  @click="reducecustomerFun(index)"
                  >-</el-button
                >
              </div>
              <!-- v-show="this.ruleForm.phones.length < 5" -->
              <el-button
                class="add"
                :disabled="dialogTitle === '查看'"
                @click="addcustomerFun"
                >+</el-button
              >
            </div>
          </el-form-item>
          <el-form-item label="开启录音：" prop="tapeStatus">
            <el-switch
              v-model="ruleForm.tapeStatus"
              :disabled="dialogTitle === '查看'"
              active-color="#13ce66"
              :active-value="0"
              :inactive-value="1"
            ></el-switch>
          </el-form-item>
          <el-form-item label="ASR服务：" prop="asrStatus">
            <el-switch
              v-model="ruleForm.asrStatus"
              :disabled="dialogTitle === '查看'"
              active-color="#13ce66"
              :active-value="0"
              :inactive-value="1"
            ></el-switch>
          </el-form-item>
          <el-form-item label="启用：" prop="status">
            <el-switch
              v-model="ruleForm.status"
              :disabled="dialogTitle === '查看'"
              active-color="#13ce66"
              :active-value="0"
              :inactive-value="1"
            ></el-switch>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="confirmFun">确 认</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  queryChannelListAPI,
  queryFictitiousPhonePage,
  updateFictitiousPhone,
  updateFictitiousStatus,
  saveFictitiousPhone,
  deleteFictitiousPhoneById,
} from "@/api/lib/api.js";
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      dialogVisible: false,
      dialogTitle: "",
      channelNo: {},
      total: 0,
      ruleForm: {
        channelNo: "",
        channelName: "",
        accessKeyId: "",
        accessKeySecret: "",
        poolKey: "",
        phones: [""],
        tapeStatus: 1,
        asrStatus: 1,
        status: 1,
      },
      form: {
        currentPage: 1,
        pageSize: 10,
      },
      rules: {
        productType: [
          { required: true, message: "请选择供应商", trigger: "change" },
        ],
        channelNo: [
          { required: true, message: "请选择渠道", trigger: "change" },
        ],
        accessKeyId: [
          { required: true, message: "请输入accessKeyId", trigger: "blur" },
        ],
        accessKeySecret: [
          { required: true, message: "请输入accessKeySecret", trigger: "blur" },
        ],
        poolKey: [
          { required: true, message: "请输入号码池key", trigger: "blur" },
        ],
        phones: [
          { required: true, validator: this.customerPhoneFun, trigger: "blur" },
        ],
      },
      channelList: [], //渠道数据
      tableData: [],
      titleName: [
        {
          title: "供应商",
          props: "productType",
          SpecialJudgment: (res) => {
            return res === 1 ? "华为云" : "阿里云";
          },
        },
        {
          title: "渠道",
          props: "channelName",
        },
        {
          title: "accessKeyId",
          props: "accessKeyId",
        },
        {
          title: "accessKeySecret",
          props: "accessKeySecret",
        },
        {
          title: "开启录音",
          props: "tapeStatus", //0-启用 1-禁用
          SpecialJudgment: (res) => {
            return res === 1 ? "禁用" : res === 0 ? "启用" : "";
          },
        },
        {
          title: "ASR服务",
          props: "asrStatus", //0-启用 1-禁用
          SpecialJudgment: (res) => {
            return res === 1 ? "禁用" : res === 0 ? "启用" : "";
          },
        },
        {
          title: "号码池Key",
          props: "poolKey",
        },
        {
          title: "虚拟号码池",
          props: "phones",
          SpecialJudgment: (res) => {
            let text = "";
            text = res.join(";");
            return text;
          },
        },
        {
          title: "启用",
          props: "status", //0-启用 1-禁用
          render: (h, params, index) => {
            return h("el-switch", {
              props: {
                value: params.status,
                "active-color": "#13ce66",
                "inactive-color": "#E3E3E3",
                activeValue: 0,
                inactiveValue: 1,
              },
              on: {
                change: (val) => {
                  updateFictitiousStatus({
                    status: val,
                    channelNo: params.channelNo,
                    productType: params.productType,
                  }).then((res) => {
                    if (res.code == "SUCCESS") {
                      this.$message.success("更新成功");
                      params.status = val;
                      this.getTableData();
                    }
                  });
                },
              },
            });
          },
        },
      ],
    };
  },
  filters: {},
  computed: {},
  watch: {
    dialogVisible(val) {
      if (!val) {
        this.dialogTitle = "";
        this.ruleForm = this.$options.data().ruleForm;
        this.channelNo = {};
        this.channelList = [];
      }
    },
  },
  created() {
    this.getTableData();
  },
  mounted() {},
  methods: {
    // 客服电话校验规则
    customerPhoneFun(rule, value, callback) {
      let reg = /^(([0][1-9]{2,3}[0-9]{5,10})|(1(3|4|5|6|7|8|9)\d{9}))$/;
      if (this.ruleForm.phones.length == 1 && this.ruleForm.phones[0] == "") {
        callback(new Error("请输入虚拟号码池号码"));
      } else {
        let con = true;
        for (let j = 0; j < this.ruleForm.phones.length; j++) {
          if (reg.test(this.ruleForm.phones[j]) == false) {
            callback("虚拟号码池号码格式错误");
            con = false;
            break;
          }
        }
        con && callback();
      }
    },
    // 点击添加虚拟号码池
    addcustomerFun() {
      const len = this.ruleForm.phones.length - 1;
      if (this.ruleForm.phones[len].trim() == "") {
        this.$message.error("请输入号码后再进行添加");
      } else {
        this.ruleForm.phones.push("");
      }
    },
    //点击去掉号码池号码
    reducecustomerFun(index) {
      this.ruleForm.phones.splice(index, 1);
    },
    // 当前分页改变
    onCurrentChange(val) {
      this.form.currentPage = val;
      this.getTableData();
    },
    // 分页大小改变
    onSizeChange(val) {
      this.form.pageSize = val;
      this.form.currentPage = 1;
      this.getTableData();
    },
    /**
     * @description 请求列表数据
     */
    getTableData() {
      queryFictitiousPhonePage(this.form).then((res) => {
        if (res.code == "SUCCESS") {
          this.tableData = res.data?.list || [];
          this.total = res.data.total;
        }
      });
    },
    /**
     * @description 打开弹窗
     */
    openDialogFun(title, row) {
      this.dialogTitle = title;
      if (this.dialogTitle !== "新增") {
        this.channelNo = {
          channelNo: row.channelNo,
          channelName: row.channelName,
        };
        if (row.phones.length == 0) {
          row.phones[0] = "";
        }
        this.ruleForm = JSON.parse(JSON.stringify(row));
      }
      this.getQueryChannelList();
      this.dialogVisible = true;
    },
    /**
     * @description 确认回调
     */
    confirmFun() {
      if (this.dialogTitle === "查看") {
        this.dialogVisible = false;
        return;
      }
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.dialogTitle === "新增") {
            saveFictitiousPhone(this.ruleForm).then((res) => {
              if (res.code == "SUCCESS") {
                this.dialogVisible = false;
                this.$message.success("新增成功");
                this.getTableData();
              }
            });
          } else {
            updateFictitiousPhone(this.ruleForm).then((res) => {
              if (res.code == "SUCCESS") {
                this.dialogVisible = false;
                this.$message.success("更新成功");
                this.getTableData();
              }
            });
          }
        }
      });
    },
    /**
     * @description 删除确认框
     */
    onDelete(scope) {
      const h = this.$createElement;
      this.$msgbox({
        title: "提示",
        type: "warning",
        message: h("p", null, [h("span", null, "确认删除？")]),
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          deleteFictitiousPhoneById({ channelNo: scope.channelNo }).then(
            (res) => {
              if (res.code == "SUCCESS") {
                this.$message.success("删除成功");
                this.getTableData();
              }
            }
          );
        })
        .catch(() => {});
    },
    /**
     * @description 获取渠道数据
     */
    getQueryChannelList() {
      queryChannelListAPI({ status: 0 }).then((res) => {
        if (res.code == "SUCCESS") {
          this.channelList = res.data;
        }
      });
    },
    //选择渠道
    changeSelect(val) {
      this.ruleForm.channelName = val.channelName;
      this.ruleForm.channelNo = val.channelNo;
    },
  },
};
</script>

<style lang="scss" scoped>
.virtual-numbe-configuration-page {
  .table-box {
    margin-top: 20px;
  }
  .dialog-box {
    .demo-ruleForm {
      .el-select {
        width: 400px;
      }
      .el-input {
        width: 400px;
      }
    }
  }
  /**虚拟号码池 */
  .addNumberBox {
    position: relative;
    .add {
      position: absolute;
      border-radius: 50%;
      width: 24px;
      height: 24px;
      padding: 0 !important;
      top: 8px;
      left: 410px;
    }
    .reduce {
      position: absolute;
      border-radius: 50%;
      width: 24px;
      height: 24px;
      padding: 0 !important;
      bottom: 8px;
      left: 410px;
    }
  }
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "virtual-numbe-configuration-page" }, [
    _c(
      "div",
      { staticClass: "add-btn" },
      [
        _vm.listFind("添加")
          ? _c(
              "el-button",
              {
                attrs: { type: "primary", size: "small" },
                on: {
                  click: function ($event) {
                    return _vm.openDialogFun("新增")
                  },
                },
              },
              [_vm._v("添加")]
            )
          : _vm._e(),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "table-box" },
      [
        _c("Table", {
          attrs: {
            "table-data": _vm.tableData,
            "title-name": _vm.titleName,
            operation: "",
            "col-class-type": "",
            "operation-width": "200",
          },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function (scope) {
                return [
                  _c(
                    "div",
                    [
                      [
                        _vm.listFind("查看")
                          ? _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "text",
                                  size: "small",
                                  sort: "primary",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.openDialogFun(
                                      "查看",
                                      scope.scopeRow
                                    )
                                  },
                                },
                              },
                              [_vm._v("查看")]
                            )
                          : _vm._e(),
                        _vm.listFind("编辑")
                          ? _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "text",
                                  size: "small",
                                  sort: "primary",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.openDialogFun(
                                      "编辑",
                                      scope.scopeRow
                                    )
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            )
                          : _vm._e(),
                      ],
                    ],
                    2
                  ),
                ]
              },
            },
          ]),
        }),
      ],
      1
    ),
    _c(
      "div",
      { ref: "pagination", staticClass: "pagination" },
      [
        _c("el-pagination", {
          attrs: {
            background: "",
            layout: "total, sizes, prev, pager, next, jumper",
            total: _vm.total,
            "page-size": _vm.form.pageSize,
            "current-page": _vm.form.currentPage,
          },
          on: {
            "size-change": _vm.onSizeChange,
            "current-change": _vm.onCurrentChange,
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "dialog-box" },
      [
        _vm.dialogVisible
          ? _c(
              "el-dialog",
              {
                attrs: {
                  title: _vm.dialogTitle,
                  visible: _vm.dialogVisible,
                  "close-on-click-modal": false,
                  width: "650px",
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.dialogVisible = $event
                  },
                },
              },
              [
                _c(
                  "el-form",
                  {
                    ref: "ruleForm",
                    staticClass: "demo-ruleForm",
                    attrs: {
                      model: _vm.ruleForm,
                      rules: _vm.rules,
                      "label-width": "140px",
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "渠道：", prop: "channelNo" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              disabled:
                                _vm.dialogTitle === "编辑" ||
                                _vm.dialogTitle === "查看",
                              "value-key": "channelNo",
                              clearable: "",
                              placeholder: "请选择渠道",
                            },
                            on: { change: _vm.changeSelect },
                            model: {
                              value: _vm.channelNo,
                              callback: function ($$v) {
                                _vm.channelNo = $$v
                              },
                              expression: "channelNo",
                            },
                          },
                          _vm._l(_vm.channelList, function (item, index) {
                            return _c("el-option", {
                              key: index,
                              attrs: { label: item.channelName, value: item },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "供应商：", prop: "productType" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              disabled: _vm.dialogTitle != "新增",
                              clearable: "",
                              placeholder: "请选择供应商",
                            },
                            model: {
                              value: _vm.ruleForm.productType,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "productType", $$v)
                              },
                              expression: "ruleForm.productType",
                            },
                          },
                          [
                            _c("el-option", {
                              attrs: { label: "华为云", value: 1 },
                            }),
                            _c("el-option", {
                              attrs: { label: "阿里云", value: 0 },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: { label: "accessKeyId：", prop: "accessKeyId" },
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入accessKeyId",
                            disabled: _vm.dialogTitle === "查看",
                            clearable: "",
                          },
                          model: {
                            value: _vm.ruleForm.accessKeyId,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.ruleForm,
                                "accessKeyId",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "ruleForm.accessKeyId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "accessKeySecret：",
                          prop: "accessKeySecret",
                        },
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入accessKeySecret",
                            disabled: _vm.dialogTitle === "查看",
                            clearable: "",
                          },
                          model: {
                            value: _vm.ruleForm.accessKeySecret,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.ruleForm,
                                "accessKeySecret",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "ruleForm.accessKeySecret",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "号码池Key：", prop: "poolKey" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入号码池Key",
                            disabled: _vm.dialogTitle === "查看",
                            clearable: "",
                          },
                          model: {
                            value: _vm.ruleForm.poolKey,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.ruleForm,
                                "poolKey",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "ruleForm.poolKey",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "虚拟号码池：", prop: "phones" } },
                      [
                        _c(
                          "div",
                          { staticClass: "addNumberBox" },
                          [
                            _vm._l(_vm.ruleForm.phones, function (item, index) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticStyle: { position: "relative" },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      clearable: "",
                                      maxlength: "11",
                                      disabled: _vm.dialogTitle === "查看",
                                      placeholder: "请输入虚拟号码池",
                                      onkeyup:
                                        "this.value=this.value.replace(/[^0-9]/g,'')",
                                    },
                                    model: {
                                      value: _vm.ruleForm.phones[index],
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.ruleForm.phones,
                                          index,
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "ruleForm.phones[index]",
                                    },
                                  }),
                                  _c(
                                    "el-button",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: index >= 1,
                                          expression: "index >= 1",
                                        },
                                      ],
                                      staticClass: "reduce",
                                      attrs: {
                                        disabled: _vm.dialogTitle === "查看",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.reducecustomerFun(index)
                                        },
                                      },
                                    },
                                    [_vm._v("-")]
                                  ),
                                ],
                                1
                              )
                            }),
                            _c(
                              "el-button",
                              {
                                staticClass: "add",
                                attrs: { disabled: _vm.dialogTitle === "查看" },
                                on: { click: _vm.addcustomerFun },
                              },
                              [_vm._v("+")]
                            ),
                          ],
                          2
                        ),
                      ]
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "开启录音：", prop: "tapeStatus" } },
                      [
                        _c("el-switch", {
                          attrs: {
                            disabled: _vm.dialogTitle === "查看",
                            "active-color": "#13ce66",
                            "active-value": 0,
                            "inactive-value": 1,
                          },
                          model: {
                            value: _vm.ruleForm.tapeStatus,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "tapeStatus", $$v)
                            },
                            expression: "ruleForm.tapeStatus",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "ASR服务：", prop: "asrStatus" } },
                      [
                        _c("el-switch", {
                          attrs: {
                            disabled: _vm.dialogTitle === "查看",
                            "active-color": "#13ce66",
                            "active-value": 0,
                            "inactive-value": 1,
                          },
                          model: {
                            value: _vm.ruleForm.asrStatus,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "asrStatus", $$v)
                            },
                            expression: "ruleForm.asrStatus",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "启用：", prop: "status" } },
                      [
                        _c("el-switch", {
                          attrs: {
                            disabled: _vm.dialogTitle === "查看",
                            "active-color": "#13ce66",
                            "active-value": 0,
                            "inactive-value": 1,
                          },
                          model: {
                            value: _vm.ruleForm.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "status", $$v)
                            },
                            expression: "ruleForm.status",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "span",
                  {
                    staticClass: "dialog-footer",
                    attrs: { slot: "footer" },
                    slot: "footer",
                  },
                  [
                    _c(
                      "el-button",
                      {
                        on: {
                          click: function ($event) {
                            _vm.dialogVisible = false
                          },
                        },
                      },
                      [_vm._v("取 消")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.confirmFun },
                      },
                      [_vm._v("确 认")]
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }